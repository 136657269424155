<template>
  <div>
    <section id="card-images">
      <b-media vertical-align="center">
        <span><img v-bind:src="`https://images.igdb.com/igdb/image/upload/t_cover_big/${options.image_id}.png`" height="150" style="display: relative"/></span>
        <h1 class="mt-1">
          {{ options.name }}
        </h1>
        <b-card-text>
          <p class="categorySummary">
            {{ options.summary }}
          </p>
        </b-card-text>
      </b-media><br>
       <router-link :to="'/categories'">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.clipModal
            size="sm"
            variant="primary"
            class="float-left clipButton"
          >
            <feather-icon
              icon="ArrowLeftIcon"
            /><span class="clipButtonText"> All Categories </span>
          </b-button>
        </router-link>
      <div v-if="empty">
        <h3>No streams currently live for this category.</h3><br>
        <router-link :to="'/categories'">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.clipModal
            size="sm"
            variant="primary"
            class="float-left clipButton"
          >
            <feather-icon
              icon="ArrowLeftIcon"
            /><span class="clipButtonText"> Return to Categories </span>
          </b-button>
        </router-link>
      </div>
      <div v-if="invalid">
        <h3>You Pepega. That Category Doesn't Exist.</h3><br>
        <router-link :to="'/categories'">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.clipModal
            size="sm"
            variant="primary"
            class="float-left clipButton"
          >
            <feather-icon
              icon="ArrowLeftIcon"
            /><span class="clipButtonText"> Return to Categories </span>
          </b-button>
        </router-link>
      </div>
    </section>
    <section id="card-images">
      <b-row>
        <b-col
          v-for="item in streams"
          :key="item.xid"
          md="5"
          lg="4"
          xl="3"
        >
          <router-link :to="'/'+item.slug">
            <b-card
              border-variant=""
              class="mb-3"
            ><progressive-img
               :src="`https://thumbnails.brime.tv/live/${item.xid}/thumbnail.jpg`"
               fallback="https://f004.backblazeb2.com/file/brime-assets/missing_thumbnail.png"
             /><span
               id="streamTitle"
             ><b-avatar
                size="45"
                :src="`https://assets.brimecdn.com/cdn-cgi/image/width=100,quality=100/users/${item.owner.xid}/avatar`"
                variant="light-primary"
                class="badge-minimal mr-1"
              /><span
                id="title"
                style="padding-left: 50px; margin-top: -40px; color: #FFF"
              >{{ item.display_name }}</span>
               <span
                 id="stream"
                 style="padding-left: 55px;"
               >
                 <strong>{{ item.stream.title }}</strong><br>
                 <span
                   id="category"
                   style="color: rgba(255, 255, 255, 0.6)"
                 >
                   {{ item.stream.category_name }}</span>
               </span></span>
              <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="primary"
            :href="item.strm"
          >
            Watch
          </b-button> -->
              <div class="live__pill">
                LIVE
              </div>
            </b-card>
          </router-link>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BButton, BMedia, BCardText, BAvatar,
} from 'bootstrap-vue'

import Vue from 'vue'

import Ripple from 'vue-ripple-directive'

Vue.filter('capitalize', string => {
  const capitalFirst = string.toUpperCase()
  return capitalFirst
})
const prefix = 'https://edge.brimelive.com/live/'
const suffix = '/thumbnail.jpg'
export default {
  components: {
    BCardText,
    BMedia,
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      regions: {},
      streams: [],
      empty: false,
      invalid: false,
      options: { category: { name: '' } },
      test: {},
      prefix,
      suffix,
      slug: '',
      cover: '',
    }
  },
  async beforeMount() {
    const pathArray = window.location.pathname.split('/')
    this.slug = pathArray[2]
    this.options = await this.categoryLookupBySlug(this.slug)
    const response = await fetch(`https://api.brime.tv/v1/streams?category_xid=${this.options.xid}`)
    const data = await response.json()
    this.regions = data
    this.streams = data.streams
    if (this.options.streams.length === 0) {
      this.empty = true
    }
    this.cover = `https://assets.brimecdn.com/categories/covers/${this.options.category.cover_image_id}.png`
  },
}
</script>
<style scoped>
/* Scrollbar styles */
.dark-layout .card {
  box-shadow: unset;
}
body {
  overflow: hidden; /* Hide scrollbars */
}
#style-4::-webkit-scrollbar-track
{
background-color: #F5F5F5;
}

#style-4::-webkit-scrollbar
{
width: 10px;
background-color: #F5F5F5;
}
.card:hover {
  transform: translateY(-8px);
  box-shadow: 0px 0px 20px rgba(115,103,240,1);
}
.card:hover img {
  filter: saturate(1.2)
}

.card img {
  filter: saturate(1);
  transition: all 0.2s;
}
.live__pill {
  z-index: 1;
  position: absolute;
  letter-spacing: 0.35px;
  left: 25px;
  top: 20px;
  padding: 2px 6px;
  background: rgba(252, 53, 55, 0.75);
  color: white;
  border-radius: 3px;
  font-size: 0.8em;
 box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
#style-4::-webkit-scrollbar-thumb
{
background-color: #000000;
border: 2px solid #555555;
}

.card {
  height: calc(100% - 3rem);
  display: flex;
  background-color: unset;
}

.card-body {
  padding: .75rem 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
}
#category {
  margin-top: 2px;
  text-align: left;
  display: block;
}
#title {
  margin-left: 5px;
  color: #b4b7bd;
  margin-top: auto;
  text-align: left;
  font-size: 15px;
    display: block;
  text-align: left;
    overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
#streamtitle {
  font-size: 14px;
  line-height: 18px;
  vertical-align: center;
}
#stream {
  font-size: 12px;
  text-align: left;
  margin-bottom: 10px;
  height: 100%;
    display: block;
  text-align: left;
    overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
#streamTitle {
  color: #7367f0;
  font-size: 14px;
  display: block;
  text-align: left;
  padding-top: 2px;
}
.badge-minimal{
  transform: translateY(11px);
}
.categorySummary {
  max-width: 700px !important; overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
</style>
